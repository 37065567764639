<template>
  <div
    v-if="$parent.checkActive(1) && $parent.languageAvailable"
    class="notifyWizardStepOne row mb-3"
  >
    <template v-if="$parent.notifyRequest && !$parent.noTranslationAvailable">
      <div class="col-2 alignCenter">
        <div class="cell">
          <font-awesome-icon
            class="mb-4 bigText"
            icon="user-tie"
            style="vertical-align: middle;"
          />
          <br>
          <span>{{ $t('support') }}</span>
        </div>
      </div>
      <div class="col-7">
        <div class="cell">
          <div class="bubble">
            <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('template') }}:</label><span>{{ $parent.installationType.name }}:&nbsp;{{ $parent.notifyRequest.originalMessage }}</span>
            <template v-if="$parent.notifyRequest.subject">
              <hr>
              <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('subject') }} ({{ $parent.notifyRequest.language }}):</label><span>{{ $parent.notifyRequest.subject }}</span>
            </template>
            <hr>
            <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('message') }} ({{ $parent.notifyRequest.language }}):</label><span>{{ $parent.notifyRequest.message }}</span>
            <hr>
            <label class="p-0 m-0 font-weight-bold mr-2">Notify over:</label><span>{{ $parent.notifyRequest.notificationMedia }}</span>
          </div>
        </div>
      </div>
      <div class="col-1">
        <div class="cell">
          <font-awesome-icon
            class="mt-4 mediumText"
            icon="long-arrow-alt-right"
            style="vertical-align: middle;"
          />
        </div>
      </div>
      <div class="col-2">
        <div class="cell alignCenter">
          <font-awesome-icon
            class="mb-4 bigText"
            icon="user"
            style="vertical-align: middle;"
          />
          <br>
          <span>{{ $parent.notifyRequest.recipient }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NotifyWizardStepOne'
}
</script>
